@import url(//fonts.googleapis.com/css?family=Roboto:400,300,100,500,700);
@import url(//fonts.googleapis.com/css?family=Roboto+Condensed:400,300,700);
@import url(//fonts.googleapis.com/css?family=Glegoo);
body {
  font-family: "Roboto", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto Condensed", sans-serif;
}

h2 {
  font-size: 26px;
}

p {
  font-family: "Roboto", sans-serif;
  line-height: 22px;
  font-size: 16px;
  font-weight: 300;
}

body {
  background: #ffffff;
}

ul {
  padding-left: 0;
}

ul li {
  list-style: none;
}

a:hover {
  text-decoration: none;
}

.section-heading {
  text-align: center;
  margin-bottom: 65px;
}

.section-heading p {
  font-size: 14px;
  font-weight: 300;
  color: #727272;
  line-height: 20px;
}

.title {
  font-size: 30px;
  line-height: 1.1;
  font-weight: 300;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 18px;
  text-transform: uppercase;
}

.subtitle-des {
  color: #727272;
  font-size: 14px;
  margin-bottom: 35px;
  font-weight: 300;
}

.pages {
  padding: 80px 0 40px;
}

.moduler {
  padding: 140px 0;
}

.social-share li {
  display: inline-block;
  margin: 3px 1px;
}

.social-share a {
  font-size: 20px;
  color: #fff;
  background: #02bdd5;
  padding: 4px 10px;
  display: inline-block;
}

.fancybox-close {
  background: url("../images/icons/close.png") no-repeat scroll 0 0 transparent;
  height: 50px;
  right: 0;
  top: 0;
  width: 50px;
}

.fancybox-next span {
  background: url("../images/icons/right.png") no-repeat scroll center center #009ee3;
  height: 50px;
  width: 50px;
  right: 0;
}

.fancybox-prev span {
  background: url("../images/icons/left.png") no-repeat scroll center center #009ee3;
  height: 50px;
  width: 50px;
  left: 0;
}

.fancybox-title {
  padding: 15px 8px;
}

.fancybox-title h3 {
  font-size: 15px;
  margin: 0;
}

.global-page-header {
  background-attachment: fixed;
  background-size: cover;
  padding: 120px 0 25px 0;
  position: relative;
  background: #02bdd5;
  color: #fff;
}

.global-page-header h2 {
  font-size: 42px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}

.global-page-header .breadcrumb {
  background: none;
  font-size: 16px;
  padding: 8px  0;
}

.global-page-header .breadcrumb .active, .global-page-header .breadcrumb li a {
  color: #fff;
}

.global-page-header .block {
  position: relative;
  z-index: 99;
  text-align: center;
}

.works-fit {
  padding: 40px 0;
}

.works-fit figure .buttons {
  left: 14%;
}

.company-description {
  margin-top: 80px;
}

.company-description .block h3 {
  margin-top: 0;
}

.company-description .block p {
  color: #727272;
}

/*=== MEDIA QUERY ===*/
.navbar-default .navbar-nav li a:hover {
  color: #02bdd5;
}

.navbar.navbar-default {
  border: 0;
  border-radius: 0;
  margin-bottom: 0;
}

.navbar.navbar-default .navbar-toggle {
  margin-top: 32px;
}

.navbar-header .navbar-brand {
  padding: 5px 0;
}

.navbar-header .navbar-brand a {
  height: auto;
  display: inline-block;
  margin-top: 8px;
}

.navbar-inverse .navbar-toggle {
  background: #444;
}

.navbar-inverse .navbar-toggle:focus {
  background: #444;
}

#top-bar {
  background: #fff;
  color: #fff;
  -webkit-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
  padding: 15px 0;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #dedede;
}

#top-bar .navbar-nav li a:hover {
  background: transparent;
}

#top-bar.animated-header {
  padding: 20px 0;
  background: #fff;
  box-shadow: none;
}

#top-bar .main-menu li {
  position: relative;
}

#top-bar .main-menu li a {
  color: #444;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 2px solid transparent;
  -webkit-transition: .3s all;
  -o-transition: .3s all;
  transition: .3s all;
  text-transform: uppercase;
}

#top-bar .main-menu li a:hover {
  color: #02bdd5;
}

.navbar-custom .nav li a {
  position: relative;
  color: #222222;
}

.navbar-right .dropdown-menu {
  right: auto;
  left: 0;
}

.navbar-custom .dropdown-menu {
  position: absolute;
  display: block;
  visibility: hidden;
  opacity: 0;
}

.navbar-toggle {
  background: #222222;
}

.navbar-toggle .icon-bar {
  background: #fff;
}

.dropdown-menu ul {
  list-style: none;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  margin-top: -5px;
  background: #fff;
  background-color: rgba(0, 0, 0, 0.95);
  box-shadow: 0 2px 0.09px rgba(0, 0, 0, 0.15);
}

.nav .open a {
  background-color: transparent;
}

.nav .open a:focus {
  background-color: transparent;
}

#top-bar .dropdown-menu ul li a {
  display: block;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 8px 0;
  color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

#hero-area {
  background: url("../images/slider.jpg") no-repeat 50%;
  background-size: cover;
  background-attachment: fixed;
  padding: 230px 0;
  color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  position: relative;
}

#hero-area:before {
  content: '';
  z-index: 9;
  background: rgba(255, 255, 255, 0.78);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#hero-area .block {
  position: relative;
  z-index: 999;
}

#hero-area h1 {
  font-size: 40px;
  line-height: 50px;
  color: #333;
  font-weight: 700;
  margin-bottom: 15px;
  text-transform: uppercase;
}

#hero-area h2 {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 38px;
  line-height: 27px;
  text-transform: uppercase;
  color: #666;
  font-family: 'Roboto', sans-serif;
  margin-top: 25px;
}

#hero-area .btn {
  background: #414141;
  border: none;
  color: #fff;
  padding: 20px 35px;
  margin-top: 30px;
  font-size: 16px;
  font-size: 13px;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: normal;
  border-radius: 0;
}

#call-to-action {
  background: #02bdd5;
  background-size: cover;
  background-attachment: fixed;
  padding: 80px 0;
  text-align: center;
  position: relative;
  color: #fff;
}

#call-to-action .block {
  position: relative;
  z-index: 99;
  color: #fff;
}

#call-to-action .block h2 {
  margin-bottom: 15px;
  color: #fff;
}

#call-to-action .block p {
  font-size: 15px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  margin-top: 20px;
}

#call-to-action .block .btn-contact {
  background: #fff;
  border: none;
  color: #02bdd5;
  padding: 16px 35px;
  margin-top: 20px;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 0;
}

#call-to-action .block .btn-contact i {
  margin-right: 10px;
}

#about {
  padding: 110px 0;
}

#about .block {
  padding: 20px 30px 0 30px;
}

#about .block h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
  text-transform: uppercase;
}

#about .block p {
  color: #727272;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 35px;
}

#about .block img {
  max-width: 100%;
}

.about-feature {
  margin-top: 50px;
}

.about-feature .block {
  color: #fff;
  width: 33.33%;
  padding: 5%;
  float: left;
}

.about-feature .block p {
  font-weight: 300;
}

.about-feature .about-feature-1 {
  background: #02bdd5;
}

.about-feature .about-feature-2 {
  background: #00B0C7;
}

.about-feature .about-feature-3 {
  background: #00A6BB;
}

.works {
  padding: 80px 0;
  background: #FCFCFC;
}

.works .block {
  position: relative;
  z-index: 99;
}

.works .block:hover .img-overly .overly {
  opacity: 1;
}

.works .block h4 {
  padding: 20px 15px;
  margin-top: 0;
  color: #666;
}

.works .block .img-overly {
  position: relative;
  background: rgba(0, 0, 0, 0.85);
}

.works .block .img-overly img {
  border-radius: 0;
}

.works .block .img-overly .overly {
  background: rgba(57, 181, 74, 0.9);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: .3s all;
  -o-transition: .3s all;
  transition: .3s all;
}

.works .block .img-overly .overly a {
  position: absolute;
  top: 45%;
  left: 22%;
}

.works .block .img-overly .overly a i {
  font-size: 30px;
  color: #fff;
}

figure {
  background: #fff;
  margin-bottom: 45px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04), 0 2px 10px 0 rgba(0, 0, 0, 0.06);
}

figure .img-wrapper {
  position: relative;
  overflow: hidden;
}

figure img {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 400ms;
  transition: transform 400ms;
}

figure:hover img {
  -webkit-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1);
}

figure:hover .overlay {
  opacity: 1;
}

figure:hover .overlay .buttons a {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

figure .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  -webkit-transition: opacity 400ms;
  transition: opacity 400ms;
}

figure .overlay a {
  display: inline-block;
  color: #fff;
  padding: 10px 23px;
  line-height: 1;
  border: 1px solid #fff;
  border-radius: 0px;
  margin: 4px;
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

figure .overlay a:hover {
  text-decoration: none;
}

figure .overlay:hover a {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

figure .buttons {
  position: absolute;
  top: 45%;
  left: 24%;
}

figure figcaption {
  padding: 20px 25px;
  margin-top: 0;
  color: #666;
}

figure figcaption h4 {
  margin: 0;
}

figure figcaption h4 a {
  color: #02bdd5;
}

figure figcaption p {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 5px;
}

#feature {
  padding: 80px 0;
}

#feature .media {
  margin: 0px 0 70px 0;
}

#feature .media .media-left {
  padding-right: 25px;
}

#feature h3 {
  color: #222222;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
  margin: 0px 0px 15px;
  font-weight: 400;
}

#feature p {
  line-height: 25px;
  font-size: 14px;
  color: #777777;
}

#feature .icon {
  text-decoration: none;
  color: #fff;
  background-color: #02bdd5;
  height: 100px;
  text-align: center;
  width: 100px;
  font-size: 50px;
  line-height: 100px;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  text-shadow: #00a4ba 1px 1px, #00a4ba 2px 2px, #00a4ba 3px 3px, #00a4ba 4px 4px, #00a4ba 5px 5px, #00a4ba 6px 6px, #00a4ba 7px 7px, #00a4ba 8px 8px, #00a4ba 9px 9px, #00a4ba 10px 10px, #00a4ba 11px 11px, #00a4ba 12px 12px, #00a4ba 13px 13px, #00a4ba 14px 14px, #00a4ba 15px 15px, #00a4ba 16px 16px, #00a4ba 17px 17px, #00a4ba 18px 18px, #00a4ba 19px 19px, #00a4ba 20px 20px, #00a4ba 21px 21px, #00a4ba 22px 22px, #00a4ba 23px 23px, #00a4ba 24px 24px, #00a4ba 25px 25px, #00a4ba 26px 26px, #00a4ba 27px 27px, #00a4ba 28px 28px, #00a4ba 29px 29px, #00a4ba 30px 30px, #00a4ba 31px 31px, #00a4ba 32px 32px, #00a4ba 33px 33px, #00a4ba 34px 34px, #00a4ba 35px 35px, #00a4ba 36px 36px, #00a4ba 37px 37px, #00a4ba 38px 38px, #00a4ba 39px 39px, #00a4ba 40px 40px, #00a4ba 41px 41px, #00a4ba 42px 42px, #00a4ba 43px 43px, #00a4ba 44px 44px, #00a4ba 45px 45px, #00a4ba 46px 46px, #00a4ba 47px 47px, #00a4ba 48px 48px, #00a4ba 49px 49px, #00a4ba 50px 50px, #00a4ba 51px 51px, #00a4ba 52px 52px, #00a4ba 53px 53px, #00a4ba 54px 54px, #00a4ba 55px 55px, #00a4ba 56px 56px, #00a4ba 57px 57px, #00a4ba 58px 58px, #00a4ba 59px 59px, #00a4ba 60px 60px, #00a4ba 61px 61px, #00a4ba 62px 62px, #00a4ba 63px 63px, #00a4ba 64px 64px, #00a4ba 65px 65px, #00a4ba 66px 66px, #00a4ba 67px 67px, #00a4ba 68px 68px, #00a4ba 69px 69px, #00a4ba 70px 70px, #00a4ba 71px 71px, #00a4ba 72px 72px, #00a4ba 73px 73px, #00a4ba 74px 74px, #00a4ba 75px 75px, #00a4ba 76px 76px, #00a4ba 77px 77px, #00a4ba 78px 78px, #00a4ba 79px 79px, #00a4ba 80px 80px, #00a4ba 81px 81px, #00a4ba 82px 82px, #00a4ba 83px 83px, #00a4ba 84px 84px, #00a4ba 85px 85px, #00a4ba 86px 86px, #00a4ba 87px 87px, #00a4ba 88px 88px, #00a4ba 89px 89px, #00a4ba 90px 90px, #00a4ba 91px 91px, #00a4ba 92px 92px, #00a4ba 93px 93px, #00a4ba 94px 94px, #00a4ba 95px 95px, #00a4ba 96px 96px, #00a4ba 97px 97px, #00a4ba 98px 98px, #00a4ba 99px 99px, #00a4ba 100px 100px;
}

.wrapper_404 h1 {
  font-size: 200px;
  color: #02bdd5;
  line-height: 1;
}

.wrapper_404 h2 {
  font-size: 50px;
  margin-top: 0;
}

.wrapper_404 .btn-home {
  background: #414141;
  border: none;
  color: #fff;
  padding: 20px 35px;
  margin-top: 10px;
  font-size: 16px;
  font-size: 13px;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: normal;
  border-radius: 0;
}

#blog-full-width {
  padding: 40px 0 80px;
}

article {
  padding: 30px 0;
}

.blog-content h2 {
  font-family: 'Roboto', sans-serif;
}

.blog-content h2 a {
  color: #444;
  font-weight: 400;
  font-size: 30px;
}

.blog-content .blog-meta {
  color: #9a9a9a;
  font-weight: 300;
  margin-bottom: 25px;
}

.blog-content .blog-meta span:after {
  content: '/';
  padding: 0 3px 0 6px;
}

.blog-content .blog-meta a {
  color: #acacac;
}

.blog-content .blog-meta p {
  margin-top: 20px;
}

.blog-content .btn-details {
  color: #02bdd5;
  padding: 0;
  background: #02bdd5;
  color: #fff;
  padding: 8px 18px;
  border-radius: 0;
  margin-top: 15px;
  border: 1px solid #fff;
  box-shadow: 0px 0px 0px 1px #02bdd5;
}

.blog-post-image {
  margin-top: 30px;
}

.sidebar {
  padding-top: 60px;
}

.sidebar .widget {
  margin-bottom: 40px;
  padding: 15px;
  border: 1px solid #dedede;
}

.sidebar .widget h3 {
  margin-top: 8px;
}

.sidebar .search input.form-control {
  border-right: 0;
  border-radius: 0;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
}

.sidebar .search button {
  border-left: 0;
}

.sidebar .search button:hover {
  background: transparent;
}

.sidebar .author .author-img img {
  width: 90px;
  border-radius: 100%;
  margin-top: -40px;
  border: 3px solid #fff;
}

.sidebar .author .author-bio p {
  font-size: 14px;
  color: #888;
}

.sidebar .categories ul li {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 0;
  list-style: none;
}

.sidebar .categories ul li span.badge {
  float: right;
  background: transparent;
  color: #444;
  border: 1px solid #dedede;
  border-radius: 0;
}

.sidebar .categories ul li a {
  color: #555;
}

.sidebar .categories ul li:last-child {
  border-bottom: none;
}

.sidebar .recent-post ul li {
  margin: 20px 0;
}

.sidebar .recent-post ul li a {
  color: #555;
  font-size: 15px;
}

.sidebar .recent-post ul li a:hover {
  color: #02bdd5;
}

.sidebar .recent-post time {
  font-weight: 300;
  color: #999;
  font-size: 12px;
}

#clients {
  padding: 30px 0 60px;
}

#clients .owl-carousel .owl-item {
  margin-right: 10px;
}

#clients .owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}

#contact-section {
  padding: 90px 0;
}

#contact-section .contact-form {
  margin-top: 42px;
}

#contact-section .contact-form .btn-send {
  color: #fff;
  outline: none;
  background: #02bdd5;
}

#contact-section .contact-form .btn-send:hover {
  border: 1px solid #02bdd5;
}

#contact-section input {
  display: block;
  height: 40px;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.428571429;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 1px;
  border: 1px solid rgba(111, 121, 122, 0.3);
  box-shadow: none;
  -webkit-box-shadow: none;
}

#contact-section textarea {
  display: block;
  border-radius: 0;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #cccccc;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

#contact-section .address, #contact-section .email, #contact-section .phone {
  text-align: center;
  background: #F7F7F7;
  padding: 20px 0 40px 0;
  margin-bottom: 20px;
}

#contact-section .address i, #contact-section .email i, #contact-section .phone i {
  font-size: 45px;
  color: #02bdd5;
}

#contact-section .address h5, #contact-section .email h5, #contact-section .phone h5 {
  font-size: 16px;
  line-height: 1.4;
}

#contact-section .address-details {
  padding-top: 70px;
}

#map-canvas {
  width: 100%;
  height: 350px;
  background-color: #CCC;
}

.error {
  padding: 10px;
  color: #D8000C;
  border-radius: 2px;
  font-size: 14px;
  background-color: #FFBABA;
}

.success {
  background-color: #6cb670;
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 10px;
}

#error, #success {
  display: none;
  margin-bottom: 10px;
}

.gallery {
  padding: 100px;
}

.gallery figure .buttons {
  left: 40%;
}

.portfolio-meta span {
  margin: 5px 10px;
  font-weight: 300;
}

.portfolio-meta span a {
  color: #fff;
}

.portfolio-single {
  padding: 80px 0 20px;
}

.portfolio-single .portfolio-content {
  padding: 40px 0;
}

.single-post {
  padding: 80px 0 20px;
}

.single-post .post-content {
  padding: 40px 0;
}

.single-post .post-content ol li, .single-post .post-content ul li {
  color: #666;
}

.single-post .media {
  border: 1px solid #dedede;
  padding: 30px 20px;
}

.single-post .media .media-body a {
  color: #02bdd5;
  font-size: 12px;
}

.single-post .media .media {
  border: none;
}

.single-post .comments {
  margin-top: 40px;
}

.single-post .post-comment {
  margin-top: 40px;
}

.single-post .post-comment h3 {
  margin-bottom: 15px;
}

.single-post .post-comment .form-control {
  box-shadow: none;
  border-radius: 0;
}

.single-post .post-comment .btn-send {
  background: #02bdd5;
  color: #fff;
  border-radius: 0;
}

#product-showcase-banner {
  padding-top: 160px;
}

#product-showcase-banner .block {
  padding: 20px 0 20px 50px;
}

#product-showcase-banner .block h2 {
  font-size: 40px;
  color: #02bdd5;
  margin-bottom: 20px;
  margin-top: 0;
}

#product-showcase-banner .block p {
  color: #666;
  line-height: 25px;
}

#product-showcase-banner .block .buttons {
  margin-top: 25px;
}

#product-showcase-banner .block .buttons .btn {
  color: #fff;
  border: none;
  padding: 12px 40px;
  -webkit-transition: .2s all;
  -o-transition: .2s all;
  transition: .2s all;
  letter-spacing: 2px;
  font-size: 15px;
}

#product-showcase-banner .block .buttons .btn-demo {
  background: #5abd4f;
  margin-right: 8px;
  border-bottom: 3px solid #429E38;
}

#product-showcase-banner .block .buttons .btn-demo:hover {
  background: #429E38;
}

#product-showcase-banner .block .buttons .btn-buy {
  background: #00AEDA;
  border-bottom: 3px solid #0190B5;
}

#product-showcase-banner .block .buttons .btn-buy:hover {
  background: #0190B5;
}

#product-showcase-banner .block .buttons .btn-buy span {
  margin-right: 8px;
  font-weight: bold;
}

#product-description {
  padding-top: 80px;
}

#product-description .block {
  height: 400px;
  overflow: hidden;
  margin-top: 35px;
  border-bottom: 1px solid #dedede;
  padding-top: 30px;
  position: relative;
  webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

#product-description .block:hover img {
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -ms-transform: translateY(20px);
  -o-transform: translateY(20px);
  transform: translateY(20px);
}

#product-description .block img {
  position: absolute;
  top: 10px;
  left: 0;
  -webkit-transform: translateY(60px);
  -moz-transform: translateY(60px);
  -ms-transform: translateY(60px);
  -o-transform: translateY(60px);
  transform: translateY(60px);
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: -moz-transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: -o-transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
}

#product-description .block .content {
  width: 60%;
  padding-top: 70px;
}

#product-description .block .content h3 {
  font-size: 32px;
  color: #5c5c5c;
}

#product-description .block .content p {
  color: #787f8c;
  line-height: 25px;
}

#related-items {
  padding: 60px 0 110px;
}

#related-items .title {
  border-bottom: 1px solid #dedede;
  padding: 10px 0;
  margin: 5px 15px 30px 15px;
  text-align: left;
  color: #777;
}

#related-items .product-details {
  margin-bottom: 0;
}

#related-items .block {
  position: relative;
  background: #fff;
  margin-bottom: 45px;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.14);
}

#related-items .block:hover .img-overly .overly {
  opacity: 1;
}

#related-items .block h4 {
  padding: 20px 15px;
  margin-top: 0;
  color: #666;
}

#related-items .block h4 span {
  float: right;
  color: #02bdd5;
}

#related-items .block .img-overly {
  position: relative;
  background: rgba(0, 0, 0, 0.85);
}

#related-items .block .img-overly img {
  border-radius: 0;
}

#related-items .block .img-overly .overly {
  background: rgba(57, 181, 74, 0.9);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: .3s all;
  -o-transition: .3s all;
  transition: .3s all;
}

#related-items .block .img-overly .overly a {
  position: absolute;
  top: 45%;
  left: 45%;
}

#related-items .block .img-overly .overly a i {
  font-size: 30px;
  color: #fff;
}

.service-page .service-parts .block {
  margin-bottom: 40px;
  text-align: center;
}

.service-page .service-parts .block i {
  font-size: 35px;
  color: #02bdd5;
}

.service-page .service-parts .block p {
  padding: 0 8px;
  font-size: 14px;
  color: #777;
  line-height: 1.7;
}

.works.service-page figure .buttons {
  position: absolute;
  top: 45%;
  left: 13%;
}

#team {
  margin: 50px 0;
}

.team-member {
  margin-top: 30px;
}

.team-member:hover .team-img img {
  opacity: .8;
}

.team-member .team-img {
  position: relative;
}

.team-member .team-img .team-pic {
  width: 100%;
}

.team-member .team_designation {
  font-size: 13px;
}

.team-member h3 {
  color: #02bdd5;
  margin-bottom: 0;
  font-size: 20px;
}

.team-member p {
  font-size: 14px;
}

.team-member .social-icons a {
  background: #02bdd5;
  color: #fff;
  padding: 4px 8px;
  display: inline-block;
  font-size: 15px;
}

.team-member .social-icons .facebook {
  padding: 4px 12px;
}

#footer {
  background: #fff;
  padding: 25px 0;
  color: #555;
}

#footer .copyright {
  font-size: 13px;
  margin-bottom: 0;
}

#footer .copyright a {
  color: #02bdd5;
}

#footer .social {
  text-align: right;
  margin-bottom: 0;
}

#footer .social li {
  display: inline-block;
  margin-right: 15px;
}

#footer .social li a {
  font-size: 22px;
  color: #02bdd5;
}

.cd-headline.slide .cd-words-wrapper {
  color: #02bdd5;
}

ul.social-icons {
  height: auto;
  overflow: hidden;
  list-style: none !important;
  margin-bottom: 10px;
}

ul.social-icons li {
  float: none;
  display: inline-block;
  height: 36px;
}

#copyright a:hover, #copyright nav .menu li a:hover {
  color: #e4e4e4 !important;
}

#copyright a {
  text-decoration: none;
}

#copyright a:hover {
  text-decoration: none;
}

/* Responsive styles
================================================== */
/* Large Devices, Wide Screens */
@media (min-width: 1200px) {
  /* Dropdown animation */
  .navbar-nav .dropdown-menu,
  .navbar-nav .dropdown.megamenu div.dropdown-menu {
    background: none;
    border: 0;
    box-shadow: none;
    display: block;
    opacity: 0;
    z-index: 1;
    visibility: hidden;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transition: visibility 500ms, opacity 500ms, -webkit-transform 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
    -moz-transition: visibility 500ms, opacity 500ms, -moz-transform 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
    -o-transition: visibility 500ms, opacity 500ms, -o-transform 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
    -ms-transition: visibility 500ms, opacity 500ms, -ms-transform 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
    transition: visibility 500ms, opacity 500ms, transform 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
  }
  .navbar-nav .dropdown:hover div.dropdown-menu,
  .navbar-nav .dropdown.megamenu:hover div.dropdown-menu {
    opacity: 1;
    visibility: visible;
    color: #777;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  .navbar-nav .dropdown-backdrop {
    visibility: hidden;
  }
}

/*		Tablet Layout: 768px.
		Gutters: 24px.
		Outer margins: 28px.
		Inherits styles from: Default Layout.
-----------------------------------------------------------------
cols    1     2      3      4      5      6      7      8
px      68    160    252    344    436    528    620    712    */
/*		Mobile Layout: 320px.
		Gutters: 24px.
		Outer margins: 34px.
		Inherits styles from: Default Layout.
---------------------------------------------
cols    1     2      3
px      68    160    252    */
@media only screen and (max-width: 767px) {
  .title {
    font-size: 40px;
  }
  .navbar-header .navbar-brand a {
    padding: 6px 8px;
  }
  #hero-area {
    padding: 140px 0;
  }
  #hero-area .btn {
    margin-top: 8px;
  }
  #about {
    padding: 70px 0;
  }
  #about .block {
    padding: 0;
  }
  figure .buttons {
    left: 16%;
  }
  #feature .media {
    margin: 0px 0px 35px;
  }
  #footer .copyright {
    text-align: center;
  }
  #footer .social {
    text-align: center;
  }
  .dropdown-menu > ul > li > a {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    padding: 15px;
    color: #333;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .dropdown-menu > ul {
    list-style: none;
    padding: 0 15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    margin-top: -5px;
    background: #fff;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 0.09px rgba(0, 0, 0, 0.15);
  }
  .navbar-collapse {
    background: #333333;
  }
  #top-bar .main-menu li > a {
    color: #fff;
    padding: 21px 10px;
    background: transparent;
  }
  #top-bar .dropdown-menu > ul {
    list-style: none;
    padding: 0 15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    margin-top: -5px;
    background: #fff;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 0.09px rgba(0, 0, 0, 0.15);
  }
  #top-bar .dropdown-menu > ul > li > a {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    padding: 15px;
    color: #333;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

/*		Wide Mobile Layout: 480px.
		Gutters: 24px.
		Outer margins: 22px.
		Inherits styles from: Default Layout, Mobile Layout.
------------------------------------------------------------
cols    1     2      3      4      5
px      68    160    252    344    436    */
